/*------------------------------------------------------------
Project Name: Vexxit 2.0;
------------------------------------------------------------*/

/* Reset
--------------------------------------------------------------------------------------------------------*/
/*http://meyerweb.com/eric/tools/css/reset/v2.0 | 20110126License:none (public domain)*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
html,
body {
  font-family: 'MaderaRegular', sans-serif !important;
  font-size: 16px;
  line-height: 22px;
  min-width: 375px;
  color: var(--textPrimary);
  -ms-overflow-style: scrollbar;
}
::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}
::-webkit-scrollbar-thumb {
  border: 0 !important;
  background-color: #b2b2b2 !important;
  border-radius: 12px !important;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
ul {
  list-style-type: none;
}
hr {
  border: 0;
  border-bottom: 1px solid #d3d3d3;
}
* {
  outline: none !important;
  box-sizing: border-box;
}

/* input changes */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

/* HTML Editor */
.html-editor {
  font-family: 'MaderaRegular', sans-serif;
  font-weight: 400;
}
.html-editor u {
  display: block;
  text-decoration: underline;
}
.html-editor strong,
.html-editor b {
  font-family: 'MaderaBold', sans-serif;
  font-weight: 700;
}
.html-editor p {
  font-size: 14px;
  line-height: 20px;
  font-family: 'MaderaRegular', sans-serif;
  font-weight: 400;
}
.html-editor h6 {
  font-size: 20px;
  line-height: 26px;
}
.html-editor .subtitle1 {
  font-size: 18px;
  line-height: 24px;
}
.html-editor ul li {
  display: flex;
  align-items: flex-start;
}
.html-editor ul li > span,
.html-editor ul li p {
  display: inline-block;
  vertical-align: top;
}
.html-editor ul li > span {
  margin-right: 4px;
  font-size: 14px;
}
.html-editor ul li + li {
  margin-top: 5px;
}
