/* Bitter Font */
@import url("https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");

/* Madera Font */
@font-face {
  font-family: "MaderaLight";
  src: url("./../fonts/Madera/MaderaLight/Madera-Light.otf");
  src: url("./../fonts/Madera/MaderaLight/Madera-Light.woff");
  src: url("./../fonts/Madera/MaderaLight/Madera-Light.woff2");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "MaderaLightItalic";
  src: url("./../fonts/Madera/MaderaLightItalic/Madera-LightItalic.otf");
  src: url("./../fonts/Madera/MaderaLightItalic/Madera-LightItalic.woff");
  src: url("./../fonts/Madera/MaderaLightItalic/Madera-LightItalic.woff2");
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: "MaderaRegular";
  src: url("./../fonts/Madera/MaderaRegular/Madera-Regular.otf");
  src: url("./../fonts/Madera/MaderaRegular/Madera-Regular.woff");
  src: url("./../fonts/Madera/MaderaRegular/Madera-Regular.woff2");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "MaderaItalic";
  src: url("./../fonts/Madera/MaderaItalic/Madera-Italic.otf");
  src: url("./../fonts/Madera/MaderaItalic/Madera-Italic.woff");
  src: url("./../fonts/Madera/MaderaItalic/Madera-Italic.woff2");
  font-style: italic;
  font-weight: 500;
}
@font-face {
  font-family: "MaderaMedium";
  src: url("./../fonts/Madera/MaderaMedium/Madera-Medium.otf");
  src: url("./../fonts/Madera/MaderaMedium/Madera-Medium.woff");
  src: url("./../fonts/Madera/MaderaMedium/Madera-Medium.woff2");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "MaderaMediumItalic";
  src: url("./../fonts/Madera/MaderaMediumItalic/Madera-MediumItalic.otf");
  src: url("./../fonts/Madera/MaderaMediumItalic/Madera-MediumItalic.woff");
  src: url("./../fonts/Madera/MaderaMediumItalic/Madera-MediumItalic.woff2");
  font-style: italic;
  font-weight: 500;
}
@font-face {
  font-family: "MaderaBold";
  src: url("./../fonts/Madera/MaderaBold/Madera-Bold.otf");
  src: url("./../fonts/Madera/MaderaBold/Madera-Bold.woff");
  src: url("./../fonts/Madera/MaderaBold/Madera-Bold.woff2");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "MaderaBoldItalic";
  src: url("./../fonts/Madera/MaderaBoldItalic/Madera-BoldItalic.otf");
  src: url("./../fonts/Madera/MaderaBoldItalic/Madera-BoldItalic.woff");
  src: url("./../fonts/Madera/MaderaBoldItalic/Madera-BoldItalic.woff2");
  font-style: italic;
  font-weight: 700;
}
@font-face {
  font-family: "MaderaExtraBold";
  src: url("./../fonts/Madera/MaderaExtraBold/Madera-ExtraBold.otf");
  src: url("./../fonts/Madera/MaderaExtraBold/Madera-ExtraBold.woff");
  src: url("./../fonts/Madera/MaderaExtraBold/Madera-ExtraBold.woff2");
  font-style: normal;
  font-weight: 800;
}

/* Bitter Font Family Classes */
.font-BitterLight {
  font-family: "Bitter", serif !important;
  font-weight: 300;
}
.font-BitterRegular {
  font-family: "Bitter", serif !important;
  font-weight: 400;
}
.font-BitterItalic {
  font-family: "Bitter", serif !important;
  font-style: italic;
}
.font-BitterMedium {
  font-family: "Bitter", serif !important;
  font-weight: 500;
}
.font-BitterMediumItalic {
  font-family: "Bitter", serif !important;
  font-weight: 500;
  font-style: italic;
}
.font-BitterSemiBold {
  font-family: "Bitter", serif !important;
  font-weight: 600;
}
.font-BitterSemiBoldItalic {
  font-family: "Bitter", serif !important;
  font-weight: 600;
  font-style: italic;
}
.font-BitterBold {
  font-family: "Bitter", serif !important;
  font-weight: 700;
}
.font-BitterBoldItalic {
  font-family: "Bitter", serif !important;
  font-weight: 700;
  font-style: italic;
}

/* Madera Font Family Classes */
.font-MaderaBold {
  font-family: "MaderaBold", sans-serif !important;
}
.font-MaderaExtraBold {
  font-family: "MaderaExtraBold", sans-serif !important;
}
.font-MaderaHairline {
  font-family: "MaderaHairline", sans-serif !important;
}
.font-MaderaLight {
  font-family: "MaderaLight", sans-serif !important;
}
.font-MaderaMedium {
  font-family: "MaderaMedium", sans-serif !important;
}
.font-MaderaRegular {
  font-family: "MaderaRegular", sans-serif !important;
}
.font-MaderaThin {
  font-family: "MaderaThin", sans-serif !important;
}
.font-MaderaUltraLight {
  font-family: "MaderaUltraLight", sans-serif !important;
}
