@charset "utf-8";

/*------------------------------------------------------------
Project Name: Vexxit 2.0;
------------------------------------------------------------*/
/* Variables */
:root {
  --primary: #094849;
  --primaryLight: #188080;
  --mediumTeal: #008080;
  --color007A7A: #007a7a;
  --primaryDarkTeal: #004648;
  --white: #ffffff;
  --charcoalBlack: #2b2b2b;
  --charcoalGrey: #272727;
  --colorFBFCFC: #fbfcfc;
  --color717171: #717171;
  --color747474: #747474;
  --color056468: #056468;
  --colorFF6F00: #ff6f00;
  --color00838B: #00838b;
  --color007479: #007479;
  --colorBEBEBE: #bebebe;
  --color009099: #009099;
}

/* CSS Starts */
a {
  text-decoration: none !important;
  transition: all 0.3s;
  color: var(--primary);
}
a:hover {
  color: var(--mediumTeal);
}
.has-underline {
  text-decoration: underline !important;
}
.has-underline:hover,
.has-underline:focus {
  text-decoration: none !important;
}
.text-nowrap {
  white-space: nowrap;
}
.clickable {
  cursor: pointer;
}
.link-primary,
.link-primary-dark {
  display: inline-block;
  color: var(--primary);
  transition: all 0.2s ease;
  text-decoration: underline !important;
}
.link-primary-dark {
  color: var(--primaryDarkTeal);
}
.link-primary:hover,
.link-primary:focus,
.link-primary-dark:hover,
.link-primary-dark:focus {
  color: var(--mediumTeal);
  text-decoration: none !important;
}
.link-white {
  display: inline-block;
  vertical-align: middle;
  color: var(--colorFBFCFC);
  transition: all 0.2s ease;
}
.link-white:hover,
.link-white:focus {
  color: var(--secondary);
}
.link-007479 {
  display: inline-block;
  vertical-align: middle;
  color: var(--color007479) !important;
  transition: all 0.2s ease;
}
.link-007479:hover,
.link-007479:focus {
  color: var(--primary) !important;
}
.link-056468 {
  display: inline-block;
  vertical-align: middle;
  color: var(--color056468) !important;
  transition: all 0.2s ease;
}
.link-056468:hover,
.link-056468:focus {
  color: var(--primary) !important;
}
a.iconic-link {
  display: inline-flex;
  align-items: center;
  text-decoration: underline !important;
  transition: all 0.2s ease;
}
a.iconic-link:hover,
a.iconic-link:focus {
  text-decoration: none !important;
  color: var(--mediumTeal);
}
a.iconic-link .MuiAvatar-root {
  margin-left: 8px;
  transition: all 0.2s ease;
}
a.iconic-link:hover .MuiAvatar-root,
a.iconic-link:focus .MuiAvatar-root {
  margin-right: 3px !important;
}

/* Common Classes */
.color-primary {
  color: var(--primary);
}
.color-mediumTeal {
  color: var(--mediumTeal);
}
.color-secondary {
  color: var(--secondary);
}
.color-white {
  color: var(--white);
}
.color-charcoalGrey {
  color: var(--charcoalGrey);
}
.color-primaryDarkTeal {
  color: var(--primaryDarkTeal);
}
.color007A7A {
  color: var(--color007A7A);
}
.color717171 {
  color: var(--color717171);
}
.color747474 {
  color: var(--color747474);
}
.color056468 {
  color: var(--color056468) !important;
}
.color565656 {
  color: #565656 !important;
}
.colorBEBEBE {
  color: #bebebe !important;
}
.color00838B {
  color: var(--color00838B) !important;
}
.color007479 {
  color: var(--color007479) !important;
}
.color009099 {
  color: var(--color009099) !important;
}
.opacity-1 {
  opacity: 1 !important;
}
.opacity-72 {
  opacity: 0.72;
}
.opacity-70 {
  opacity: 0.7;
}
.text-uppercase {
  text-transform: uppercase;
}
.colorFF6F00 {
  color: var(--colorFF6F00) !important;
}
.text-decoration-underline {
  text-decoration: underline !important;
}
.font-10 {
  font-size: 0.625rem;
}
.font-12 {
  font-size: 0.75rem;
}
.tight-0 {
  letter-spacing: 0 !important;
}
.tight-025 {
  letter-spacing: 0.25px !important;
}
.font-14 {
  font-size: 0.875rem;
  line-height: 1.42857;
}
.radius-full {
  border-radius: 300px !important;
}
.w-100 {
  width: 100%;
}
.max-w-1096 {
  max-width: 1096px;
  margin: 0 auto;
}
.max-w-946 {
  max-width: 946px;
  margin: 0 auto;
}
.max-w-872 {
  max-width: 872px;
  margin: 0 auto;
}
.max-w-797 {
  max-width: 872px;
  margin: 0 auto;
}
.max-w-648 {
  max-width: 648px;
  margin: 0 auto;
}
.min-h-auto {
  min-height: auto !important;
}
.br-0 {
  border-right: 0;
}
.bl-0 {
  border-left: 0 !important;
}
.lnh-1 {
  line-height: 1;
}
.lnh-14 {
  line-height: 1.4;
}
.lnh-145 {
  line-height: 1.45;
}
.flex-grow1 {
  flex-grow: 1 !important;
}
.position-relative {
  position: relative;
}
.text-ellipsis {
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}
.two-line-ellipsis {
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.dual-icon .MuiButton-endIcon {
  margin-top: -4px;
  margin-left: 8px;
  width: 16px;
  height: 16px;
}
.dual-icon .MuiButton-startIcon {
  margin-top: -3px;
  margin-right: 6px;
  width: 16px;
  height: 16px;
}
.dual-icon .MuiAvatar-root.default,
.dual-icon:hover .MuiAvatar-root.active,
.dual-icon:focus .MuiAvatar-root.active {
  display: block;
  margin-top: -3px;
  overflow: visible;
}
.dual-icon .MuiAvatar-root.active,
.dual-icon:hover .MuiAvatar-root.default,
.dual-icon:focus .MuiAvatar-root.default {
  display: none;
}

.default-link {
  color: var(--charcoalGrey);
}
.default-link:hover,
.default-link:focus {
  color: var(--primaryLight);
}
.ternary-link {
  color: var(--color00838B);
}
.ternary-link:hover,
.ternary-link:focus {
  color: var(--primary);
}
.static-error.MuiFormHelperText-root {
  position: static;
  white-space: normal;
  max-width: 100%;
  overflow: visible;
}

.select-control.mb-0 .MuiOutlinedInput-root {
  margin-bottom: 0;
}
.MuiFormControl-root.mb-0 .MuiOutlinedInput-root {
  margin-bottom: 0;
}

.inline-price {
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 4px;
  text-align: right;
}

/* Left dot */
.left-dot {
  position: relative;
  margin-right: 8px;
  line-height: 22px;
  font-family: 'MaderaMedium', sans-serif;
  letter-spacing: 0.15px;
}
.left-dot::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -1px;
  width: 3px;
  height: 3px;
  background: var(--charcoalGrey);
  border-radius: 50%;
  margin-right: 8px;
}
.professional-profile-banner > .user .user-avatar.image-background {
  background: #bdbdbd;
}

/* loader wrapper */
.loader-open .loader-wrapper {
  opacity: 1;
  visibility: visible;
}
.loader-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.66);
  z-index: 999999;
  transition: all 0.2s ease;
  visibility: hidden;
}

/* Login */
.login-wrapper {
  min-height: 100vh;
  background: #f2f7f8;
}
.login-wrapper .login-inner {
  display: flex;
  flex-direction: column;
  padding: 48px;
  min-height: 100vh;
}
.login-wrapper .login-inner .login-header {
  flex-shrink: 0;
  text-align: center;
}
.login-wrapper .login-inner .login-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
.login-wrapper .login-inner .login-body .login-form {
  padding: 40px;
  margin: 50px 0;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.23), 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  max-width: 440px;
}
.login-wrapper .login-inner .login-footer {
  flex-shrink: 0;
  text-align: center;
}

/* layout */
.layout {
  min-height: 100vh;
}

/* header */
.header.MuiAppBar-root {
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.23), 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 14px 0 14px 8px;
  transition: 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  left: 0;
  right: auto;
  min-width: 360px;
}

/* sidebar */
.sidebar .MuiDrawer-paper {
  right: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-basis: 280px;
  max-width: 280px;
  padding: 12px;
  background: var(--primary);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.22), 0px 14px 28px rgba(0, 0, 0, 0.25);
}
.sidebar .logo {
  flex-shrink: 0;
  margin: 12px 12px 40px;
}
.sidebar .main-navigation {
  flex-grow: 1;
  margin-bottom: 20px;
}
.sidebar .main-navigation .nav-item {
  margin-bottom: 6px;
}
.sidebar .main-navigation .nav-item .nav-link {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 10px 12px 16px;
}
.sidebar .main-navigation .nav-item .nav-link .MuiTypography-root {
  opacity: 0.64;
}
.sidebar .main-navigation .nav-item .nav-link .user-avatar {
  margin-right: 10px;
  margin-bottom: -5px;
  opacity: 0.64;
}
.sidebar .main-navigation .nav-item .nav-link:hover .user-avatar,
.sidebar .main-navigation .nav-item .nav-link:hover .MuiTypography-root {
  opacity: 1;
}
.sidebar .main-navigation .nav-item.active .nav-link .user-avatar,
.sidebar .main-navigation .nav-item.active .nav-link .MuiTypography-root {
  opacity: 1;
}
.sidebar .main-navigation .nav-item.active .nav-link {
  background: rgba(0, 0, 0, 0.16);
}
.sidebar .main-navigation .nav-item .nav-link:focus {
  outline: 2px solid #ffffff;
  opacity: 1;
}
.sidebar .main-navigation .nav-item .nav-link .nav-alert-badge.MuiBadge-root {
  margin-left: auto;
  margin-top: 5px;
  width: 10px;
  height: 10px;
  background: #e53935;
  border-radius: 50%;
}
.sidebar .profile-section {
  flex-shrink: 0;
}
.profile-section .btn-profile {
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.16);
  text-align: left;
  padding: 9px 16px 12px;
  width: 100%;
  justify-content: flex-start;
}
.profile-section .btn-profile .MuiButton-endIcon {
  margin-left: auto;
}

.profile-section .btn-profile:hover,
.profile-section .btn-profile:focus,
.profile-section .btn-profile.MuiButtonBase-root[aria-expanded='true'] {
  background: rgba(0, 0, 0, 0.4);
}
.profile-section .btn-profile .user-avatar.MuiAvatar-root {
  border: 2px solid #4cb1bc;
  border-radius: 50%;
}
.profile-section .btn-profile .user-avatar.MuiAvatar-root .MuiAvatar-img {
  border-radius: 50%;
  overflow: hidden;
}
.profile-section .btn-profile .username-email,
.profile-menu-header .username-email {
  display: block;
  max-width: 223px;
  opacity: 0.64;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.profile-section .btn-profile .username-email {
  max-width: 205px;
}
.profile-menu.MuiPaper-root {
  width: 255px;
  margin-left: -4px;
  border-radius: 8px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.23), 0px 10px 20px rgba(0, 0, 0, 0.19);
}
.profile-menu-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  padding: 12px 16px;
}
.profile-menu-header .user-avatar {
  border: 2px solid #056468;
  border-radius: 50%;
}
.profile-menu-header .user-avatar.MuiAvatar-root .MuiAvatar-img {
  border-radius: 50%;
  overflow: hidden;
}

/* main-content */
.wrapper {
  min-height: 100vh;
}
.layout {
  padding-top: 72px;
  transition: 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background: #e6e6e6;
}
.main-content {
  flex-grow: 1;
  padding: 24px;
}

.card {
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.24), 0px 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  overflow: hidden;
}
.professional-profile-banner {
  position: relative;
  padding: 16px;
}
.professional-profile-banner > .user {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.professional-profile-banner > .user .user-details {
  display: block;
  position: absolute;
  top: 34px;
  left: 0;
  background: #094849;
  padding: 9px 72px 15px 128px;
  border-radius: 0px 100px 100px 0px;
  z-index: 0;
  min-width: 410px;
}
.professional-profile-banner > .user .user-avatar {
  width: 96px;
  height: 120px;
  border-radius: 24px;
  border: 4px solid var(--white);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.23), 0px 3px 6px rgba(0, 0, 0, 0.16);
  margin-right: 24px;
  z-index: 1;
  background-color: var(--white);
  position: relative;
}
.professional-profile-banner > .user .user-avatar.primary-avatar {
  color: var(--primary);
}
.professional-profile-banner > .user .user-avatar.default-avatar {
  background: var(--primary);
}
.professional-profile-banner > .user .user-avatar.default-avatar .MuiAvatar-img {
  width: 64px;
  height: 64px;
}
.professional-profile-banner > .user .user-avatar .user-avatar-img {
  width: 88px;
  height: 112px;
  border-radius: 20px;
}
.professional-profile-banner > .user .user-name {
  color: var(--white);
  font-size: 24px;
  line-height: 34px;
  max-width: 450px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.professional-profile-banner > .user .user-designation {
  color: var(--white);
  font-family: 'MaderaMedium', sans-serif;
  margin-bottom: 2px;
  letter-spacing: 0.15px;
  opacity: 0.64;
}
.professional-profile-banner .lifted-section {
  padding-left: 171px;
  margin-top: -56px;
}

/* key-data */
.key-data .key {
  opacity: 0.72;
  color: var(--charcoalGrey);
  margin-bottom: 1px;
  word-break: break-word;
}
.key-data .value {
  color: var(--charcoalGrey);
  word-break: break-word;
}

/* border-box */
.border-box {
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 12px;
}
.border-box .action {
  border-top: 1px solid #e1e1e1;
  margin: 0 -12px -12px;
  width: calc(100% + 24px);
  padding: 7px 12px 10px;
  margin-top: 12px;
}

/* light-chips */
.light-chips.MuiChip-root {
  min-height: 30px;
  height: 30px;
  padding: 4px 0 8px;
  border: 1px solid #bebebe;
  border-radius: 20px;
}
.light-chips.MuiChip-root .MuiChip-label {
  font-family: 'MaderaRegular', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--charcoalGrey);
}

/* collapsible table */
.collapsible-table.MuiTableContainer-root {
  background: var(--white);
  border: 1px solid #e1e1e1;
  border-radius: 16px;
  overflow: hidden;
}
.collapsible-table.MuiTableContainer-root .MuiCollapse-wrapper {
  display: inline-grid;
}
.collapsible-table.MuiTableContainer-root .MuiCollapse-wrapper .MuiCollapse-wrapperInner {
  overflow-x: auto;
}
.collapsible-table.MuiTableContainer-root
  .MuiCollapse-wrapper
  .MuiCollapse-wrapperInner
  .MuiTable-root {
  min-width: 650px;
}
.collapsible-table.MuiTableContainer-root.full-width .MuiTable-root {
  min-width: auto;
}
.collapsible-table.MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root {
  border: 0;
  border-radius: 16px;
  transition: none;
}
.collapsible-table.MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root.collapsible-heading {
  border-top: 1px solid #e1e1e1;
}
.collapsible-table.MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root.collapsible-heading.open {
  border-bottom: 1px solid #e1e1e1;
}
.collapsible-table.MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root.collapsible-heading:first-of-type {
  border-top: 0;
}
.collapsible-table.MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root.collapsible-heading
  .MuiTableCell-root {
  padding: 9px 18px 10px 18px;
  height: 56px;
}
.collapsible-table.MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root
  .parent-table-title
  > .MuiCheckbox-root {
  margin: -2px 2px -2px -2px;
}
.collapsible-table.MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root
  .inner-table-wrapper {
  max-width: 100%;
  padding-left: 56px;
  margin-left: auto;
  transition: height 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: auto;
}
.collapsible-table.MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root
  .inner-table-wrapper
  .MuiCollapse-wrapper
  .inner-table {
  margin-bottom: 8px;
}
.collapsible-table.MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root
  .inner-table-wrapper
  .MuiCollapse-wrapper
  .inner-table
  .MuiTableHead-root
  .MuiTableCell-head {
  font-family: 'MaderaBold', sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--color4E4E4E);
  border-bottom: 1px solid #e1e1e1;
  padding: 9px 9px 11px;
  white-space: nowrap;
}
.collapsible-table.MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root
  .inner-table-wrapper
  .MuiCollapse-wrapper
  .inner-table
  .MuiTableHead-root
  .MuiTableCell-head:first-child {
  padding: 0;
}
.collapsible-table.MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root
  .inner-table-wrapper
  .MuiCollapse-wrapper
  .inner-table
  .MuiTableBody-root
  .MuiTableCell-body:first-child
  .MuiCheckbox-root,
.collapsible-table.MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root
  .inner-table-wrapper
  .MuiCollapse-wrapper
  .inner-table
  .MuiTableBody-root
  .MuiTableCell-body:first-child
  .MuiCheckbox-root {
  margin-top: -7px;
  margin-right: -8px;
}
.collapsible-table.MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root
  .inner-table-wrapper
  .MuiCollapse-wrapper
  .inner-table
  .MuiTableBody-root
  .MuiTableCell-body:first-child {
  padding: 7px 0 11px;
}
.collapsible-table.MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root
  .inner-table-wrapper
  .MuiCollapse-wrapper
  .inner-table
  .MuiTableBody-root
  .MuiTableCell-body {
  font-family: 'MaderaRegular', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: var(--color4E4E4E);
  padding: 7px 9px 11px;
  vertical-align: top;
  border-bottom: 1px solid #e1e1e1;
  height: 40px;
}
.collapsible-table.MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root
  .inner-table-wrapper
  .MuiCollapse-wrapper
  .inner-table
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-body:last-child {
  padding-right: 18px;
}
.collapsible-table.MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root
  .inner-table-wrapper
  .MuiCollapse-wrapper
  .inner-table
  .MuiTableBody-root
  .MuiTableRow-root:last-child
  .MuiTableCell-body {
  border-bottom: 0;
}

.collapsible-table.MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root
  .inner-content-wrapper {
  position: relative;
}
.collapsible-table.MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root
  .inner-content-wrapper
  .inner-content {
  padding: 16px 20px 0;
  max-height: 600px;
  overflow: auto;
}
.collapsible-table.MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root
  .inner-content-wrapper
  .bottom-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: var(--white);
  padding: 12px 20px;
  border-radius: 0 0 16px 16px;
  border-top: 1px solid #e1e1e1;
}
.collapsible-table.MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root
  .inner-table-wrapper
  .MuiCollapse-wrapper
  .inner-table
  .MuiTableBody-root
  .MuiTableCell-body
  .listing
  > li::before {
  top: 8px;
  transform: none;
}

.listing {
  margin: 0;
}
.listing > li {
  position: relative;
  font-size: 0.875rem;
  line-height: 24px;
  letter-spacing: 0.25px;
  padding: 0 8px 0 14px;
  margin-left: 4px;
  display: inline-block;
}
.listing > li + li {
  margin-top: 4px;
}
.listing > li::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  margin-top: 3px;
  left: 0;
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: var(--charcoalGrey);
}

.action-focus-table.MuiTableContainer-root
  .MuiTable-root
  .MuiTableRow-root
  .MuiTableCell-root:first-child {
  padding-left: 0;
}
.action-focus-table.MuiTableContainer-root .MuiTable-root .MuiTableRow-root .MuiTableCell-root {
  color: #4e4e4e;
  padding: 8px 10px 12px;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}
.action-focus-table.MuiTableContainer-root
  .MuiTable-root
  .MuiTableRow-root
  .MuiTableCell-root:last-child
  .border-left {
  padding-left: 10px;
  height: 32px;
  border-left: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  margin-bottom: -2px;
}
.btn-link {
  font-size: 1rem;
  line-height: 22px;
  color: var(--primary);
  font-family: 'MaderaMedium', sans-serif;
  padding: 4px 8px;
}

.capital-title.MuiTypography-root {
  font-family: 'MaderaBold', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: #747474;
}
.MuiDialogContent-root .separator {
  width: calc(100% + 48px);
  margin: 24px -24px 16px -24px;
}
.fixed-footer {
  padding: 24px !important;
  border-top: 1px solid #bebebe;
}

/* no-data-found */
.full-height-card {
  min-height: calc(100vh - 207px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-data-found {
  padding: 120px 40px;
  text-align: center;
}

.profile-details-actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  min-height: 136px;
  margin-bottom: -13px;
}
.top-actions {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
}

/* password verification */
.password-verification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 22px;
}
.password-verification .password-verification-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  color: #747474;
}
.password-verification .bar-wrapper {
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-grow: 1;
  max-width: 380px;
}
.password-verification .bar-wrapper .password-info.MuiAvatar-root {
  margin-left: 90px;
}
.password-verification .bar-wrapper .bar {
  height: 4px;
  border-radius: 16px;
  background: rgba(224, 224, 224, 0.9);
  width: 100%;
  margin: 0 4px;
  max-width: 45px;
}
.password-verification .bar-wrapper .bar.weak {
  background: #ff1744;
}
.password-verification .bar-wrapper .bar.fair {
  background: #ffc400;
}
.password-verification .bar-wrapper .bar.strong {
  background: #097837;
}
/* password requirement  */
.password-requirement > li {
  position: relative;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  padding-left: 32px;
  padding-bottom: 4px;
  font-family: 'MaderaRegular', sans-serif;
  letter-spacing: 0.5px;
}
.password-requirement > li + li {
  margin-top: 10px;
}
.password-requirement > .active:before {
  background-color: var(--primary);
}
.password-requirement > li:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 22px;
  width: 22px;
  background: #80c7d1 url('./assets/images/check-white-icon.svg') no-repeat center center;
  background-size: 22px 22px;
  transition: all 0.2s cubic-bezier(0.35, 0, 0.25, 1);
  border-radius: 500px;
}

.add-note .fixed-btn.MuiIconButton-root {
  position: fixed;
  width: 48px;
  height: 48px;
  bottom: 20px;
  right: 20px;
}
.MuiButtonBase-root.btn-contained-primary {
  background: #094849;
}
.MuiButtonBase-root.btn-contained-primary:hover {
  background: #063233;
}
.notes-popover.simple-tooltip .MuiPaper-root {
  margin-top: -10px;
  min-width: 380px;
  max-width: 380px;
  padding: 0 !important;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.22), 0px 14px 28px rgba(0, 0, 0, 0.25) !important;
}
.notes-wrapper {
  display: flex;
  flex-direction: column;
}
.notes-wrapper .notes-list {
  padding: 16px 16px 0;
  flex-grow: 1;
  max-height: 350px;
  min-height: 350px;
  overflow: auto;
}
.notes-wrapper .notes-list > li {
  line-height: 0;
  display: flex;
}
.notes-wrapper .notes-list > li .note-message-wrapper .note-message-header {
  margin-bottom: 6px;
}
.notes-wrapper .notes-list > li .note-message-wrapper .note-message-header .time {
  font-size: 10px;
  line-height: 14px;
  color: #4e4e4e;
  opacity: 0.7;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.notes-wrapper .notes-list > li .note-message-wrapper .note-message-body {
  background: #e1f1f4;
  border-radius: 0px 8px 8px 8px;
  padding: 5px 8px;
  display: inline-block;
}
.notes-wrapper .notes-list > li + li {
  margin-top: 24px;
  white-space: pre-wrap;
  word-break: break-word;
}
.notes-wrapper .notes-list .note-message-wrapper {
  flex-grow: 1;
}
.notes-wrapper .notes-action-bar {
  display: flex;
  align-items: flex-end;
  flex-shrink: 0;
  padding: 20px 12px;
}
.notes-wrapper
  .notes-action-bar
  .MuiFormControl-root
  > .MuiOutlinedInput-root
  .MuiOutlinedInput-input {
  margin: auto 0;
  padding: 0 4px 0 !important;
}

/* rating-count-dropdown  */
.light-primary.MuiBadge-root {
  font-size: 10px;
  line-height: 17px;
  min-width: 22px;
  height: 22px;
  padding: 1px 3px 4px;
  background: #e1f1f4;
  color: #094849;
  border-radius: 20px;
  font-family: 'MaderaBold', sans-serif;
  font-weight: 700;
  margin-right: 9px;
  display: inline-flex;
  justify-content: center;
}
.rating-count.MuiButton-textPrimary {
  font-size: 12px;
  line-height: 12px;
  width: 18px;
  height: 18px;
  padding: 1px 5px 4px;
  font-family: 'MaderaBold', sans-serif;
  font-weight: 700;
  margin-left: 6px;
}
.rating-count-dropdown li {
  position: relative;
  line-height: 16px;
  font-family: 'MaderaRegular', sans-serif;
  font-weight: 400;
  letter-spacing: 0.4px;
  color: #4e4e4e;
}
.MuiPopover-root.rating-count-dropdown.simple-tooltip .MuiPaper-root {
  min-width: 146px;
  margin-top: -6px;
  min-height: 78px;
  padding: 10px 12px;
}
.rating-count-dropdown li + li {
  margin-top: 6px;
}
.MuiPopover-root.rating-count-dropdown .MuiBackdrop-root {
  display: none !important;
}

/* audit timeline wrapper */
.audit-timeline-wrapper {
  text-align: center;
  padding: 15px 0 30px;
  max-width: 530px;
  margin: 0 auto;
}
.audit-timeline-wrapper .audit-timeline {
  text-align: center;
  position: relative;
}
.audit-timeline-wrapper.project-history {
  max-width: 624px;
  margin: 0 auto;
}
.audit-timeline::before {
  content: '';
  position: absolute;
  top: 22px;
  bottom: 22px;
  left: 50%;
  border-left: 2px solid #b3dce3;
  margin-left: -1px;
}
.audit-timeline .right-handle,
.audit-timeline .left-handle {
  display: block;
  position: relative;
  margin-top: 3px;
}
.audit-timeline .right-handle .box,
.audit-timeline .left-handle .box {
  display: inline-block;
  background: #f2f7f8;
  padding: 3px 10px 6px;
  border-radius: 6px;
  position: relative;
  max-width: 244px;
  min-width: 244px;
}
.audit-timeline-wrapper.project-history .audit-timeline .right-handle .box,
.audit-timeline-wrapper.project-history .audit-timeline .left-handle .box {
  max-width: calc(50% - 22px);
  min-width: calc(50% - 22px);
}
.audit-timeline-wrapper.project-history .audit-timeline .left-handle .box {
  text-align: left;
}
.audit-timeline .right-handle,
.audit-timeline .left-handle .box {
  text-align: right;
}
.audit-timeline .left-handle,
.audit-timeline .right-handle .box {
  text-align: left;
}
.audit-timeline .right-handle span,
.audit-timeline .left-handle .box span {
  display: inline-block;
}
.audit-timeline .left-handle .user-detail {
  display: inline-flex;
  flex-direction: row-reverse;
}
.audit-timeline .right-handle::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-6px, -50%);
  border: 2px solid #094849;
  background: #ffffff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}
.audit-timeline .left-handle::after {
  content: '';
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(6px, -50%);
  border: 2px solid #094849;
  background: #ffffff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}
.audit-timeline .right-handle.completed::before {
  right: 50%;
  transform: translate(-10px, -50%);
  border: 0;
  background: #094849 url('./assets/images/check-white.svg') no-repeat center center;
  background-size: 14px 14px;
  width: 20px;
  height: 20px;
}
.audit-timeline .left-handle.completed::after {
  right: 50%;
  transform: translate(10px, -50%);
  border: 0;
  background: #094849 url('./assets/images/check-white.svg') no-repeat center center;
  background-size: 14px 14px;
  width: 20px;
  height: 20px;
}
.audit-timeline .left-handle.steps-wrapper,
.audit-timeline .right-handle.steps-wrapper {
  margin-top: 14px;
}
.audit-timeline .left-handle .box.steps,
.audit-timeline .right-handle .box.steps {
  width: 50%;
  background: transparent;
  padding: 0;
  border-radius: 0;
}
.audit-timeline .left-handle .box.steps .step-name,
.audit-timeline .right-handle .box.steps .step-name {
  display: inline-block;
  background: #f2f7f8;
  padding: 3px 10px 6px;
  border-radius: 6px;
  max-width: 244px;
  font-family: 'MaderaMedium', sans-serif;
  color: #007479;
}

/* copied text wrapper */
.copied-text-wrapper {
  border-radius: 50px;
  max-width: 320px;
  display: flex;
  align-items: center;
  position: relative;
  margin-left: auto;
  flex-grow: 1;
  margin-right: -24px;
  margin-top: -2px;
}
.copied-text {
  border-radius: 50px;
  border: 1px solid #bebebe;
  background: #ffffff;
  font-family: 'Bitter', serif !important;
  color: #272727;
  font-style: italic;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 8px 55px 8px 20px;
  flex-grow: 1;
  height: 40px;
  margin-right: 16px;
}
.copied-text-wrapper .btn-copy {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  padding: 0;
  position: relative;
  left: -40px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.23), 0px 3px 6px rgba(0, 0, 0, 0.16) !important;
}

.client-dummy-avatar {
  background: var(--primary);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
}

/* project accordion */
.project-accordion.MuiAccordion-root {
  margin: 20px 0 !important;
  border: 1px solid #e1e1e1;
  border-radius: 18px !important;
}
.project-accordion.MuiAccordion-root:before {
  display: none;
}
.project-accordion.MuiAccordion-root.Mui-expanded .MuiAccordionSummary-root {
  border-bottom: 1px solid #e1e1e1;
}
.project-accordion.MuiAccordion-root
  .MuiAccordionSummary-root.MuiButtonBase-root[aria-expanded='true'],
.project-accordion.MuiAccordion-root .MuiAccordionSummary-root.MuiButtonBase-root.Mui-focusVisible {
  background: none;
}
.project-accordion.MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 12px 16px 8px;
}
.project-accordion.MuiAccordion-root
  .MuiAccordionSummary-root
  .MuiAccordionSummary-content
  .heading.MuiTypography-root {
  padding-bottom: 6px;
  font-family: 'MaderaMedium', sans-serif;
  font-weight: 500;
  color: var(--color007A7A);
}
.project-accordion.MuiAccordion-root
  .MuiAccordionSummary-root
  .MuiAccordionSummary-content
  .transparent-input {
  border: 0;
  font-family: 'MaderaMedium', sans-serif;
  font-weight: 500;
  padding: 6px 0 6px;
  margin: -6px 0;
  color: var(--color007A7A);
  font-size: 1.125rem;
  line-height: 1.3;
  flex-grow: 1;
}
.project-accordion.MuiAccordion-root
  .MuiAccordionSummary-root
  .MuiAccordionSummary-content
  .transparent-input::placeholder {
  color: var(--color007A7A);
  opacity: 0.5;
}
.project-accordion.MuiAccordion-root
  .MuiAccordionSummary-root
  .MuiAccordionSummary-content
  .transparent-input.error::placeholder {
  color: #d32f2f;
  opacity: 0.6;
}
.project-accordion.MuiAccordion-root
  .MuiAccordionSummary-root
  .MuiAccordionSummary-content
  .right-action {
  display: flex;
  align-items: center;
}
.project-accordion.MuiAccordion-root .MuiCollapse-root .MuiAccordionDetails-root {
  padding: 24px;
}
.project-accordion.MuiAccordion-root .MuiCollapse-root .MuiAccordionDetails-root .user {
  display: flex;
  align-items: center;
  padding-bottom: 22px;
  margin-bottom: 22px;
  border-bottom: 1px solid #e1e1e1;
}
.project-accordion.MuiAccordion-root .MuiCollapse-root .MuiAccordionDetails-root .user .user-image {
  margin-right: 16px;
  width: 80px;
  height: 102px;
  border-radius: 52px;
}
.project-accordion.MuiAccordion-root .MuiCollapse-root .MuiAccordionDetails-root .inner-card {
  background: #f6f6f6;
  border-radius: 12px;
  padding: 15px 4px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.project-accordion.MuiAccordion-root .MuiCollapse-root .MuiAccordionDetails-root .inner-card > li {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  width: 33.3333%;
  max-width: 33.3333%;
}
.project-accordion.MuiAccordion-root
  .MuiCollapse-root
  .MuiAccordionDetails-root
  .inner-card
  > li
  + li {
  border-left: 1px solid #e1e1e1;
}
/* Alert line text */
.alert-line-text {
  position: relative;
  display: flex;
  line-height: 24px;
  margin-bottom: 10px;
  letter-spacing: 0.5px;
  color: var(--color747474);
}
.alert-line-text::before {
  content: '';
  position: relative;
  top: 11px;
  display: flex;
  align-items: center;
  width: 6px;
  height: 6px;
  background-color: var(--color747474);
  border-radius: 50%;
  margin-right: 10px;
  color: var(--white);
  font-size: 12px;
  line-height: 12px;
  flex-shrink: 0;
}

.deliverables-list.no-data {
  margin-top: 0;
}
.deliverables-list.no-data > li {
  padding-bottom: 30px;
  margin: -10px 0 0;
}
.deliverables-list > li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 10px 9px 12px;
  border-radius: 10px;
  margin-bottom: 12px;
  background: #f2f7f8;
}
.deliverables-list > li .btn-text-underlined.MuiButtonBase-root {
  margin-right: -14px;
}
.deliverables-list > li .MuiCheckbox-root {
  margin: -9px -11px -9px -9px;
}

.matched-pro-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: #ffffff;
  border: 1px solid #bebebe;
  border-radius: 15px;
  min-height: 100%;
}
.matched-pro-card .card-body {
  text-align: center;
  margin-bottom: 20px;
}
.matched-pro-card .user-image {
  width: 78px;
  height: 100px;
  margin: 0 auto 10px;
  overflow: hidden;
  border-radius: 40px;
}

/* textarea */
.textarea.MuiFormControl-root .MuiOutlinedInput-root {
  padding: 20px 26px;
  border-radius: 16px;
}
.textarea.MuiFormControl-root .MuiOutlinedInput-root textarea {
  padding: 0;
}
.textarea.MuiFormControl-root .MuiOutlinedInput-root textarea::-webkit-input-placeholder {
  color: var(--charcoalGrey);
  opacity: 0.72;
}
.textarea.MuiFormControl-root .MuiOutlinedInput-root textarea::-ms-input-placeholder {
  color: var(--charcoalGrey);
  opacity: 0.72;
}
.textarea.MuiFormControl-root .MuiOutlinedInput-root textarea::placeholder {
  color: var(--charcoalGrey);
  opacity: 0.72;
}

/* user-card */
.user-card {
  border-radius: 18px;
  background: #f2f7f8;
  padding: 24px 30px;
}
.user-card .user-img.MuiAvatar-root {
  border-radius: 48px;
}
.price-table {
  width: 100%;
}
.price-table tr td {
  padding: 8px 16px;
}

/* separate listing */
.separate-listing li {
  position: relative;
  display: inline-block;
  line-height: 16px;
}
.separate-listing li + li {
  padding-left: 18px;
}
.separate-listing li + li::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  margin: -2px 8px 0;
  display: inline-block;
  vertical-align: top;
  width: 3px;
  height: 3px;
  background: var(--color747474);
  border-radius: 50%;
}

.light-bordered-box {
  border: 1px solid #e1e1e1;
  border-radius: 18px;
  padding: 24px;
}
.light-bg-box {
  background: #e1f1f4;
  border-radius: 6px;
  padding: 8px 16px 12px;
}

/* check action grid */
.check-action-grid {
  width: 100%;
}
.check-action-grid tr td {
  padding: 10px 20px;
}
.check-action-grid tr td:first-child {
  padding-left: 0;
}
.check-action-grid tr td:last-child {
  padding-right: 0;
}
/* user listing */
.user-listing {
  max-height: 480px;
  overflow: auto;
  display: block;
  margin: 0 -24px -20px;
  padding: 0 24px 10px;
}
.user-listing li {
  padding: 12px 0;
  border-bottom: 1px solid #e1e1e1;
}
.user-listing li:last-child {
  border-bottom: 0;
}
.user-listing li > div {
  display: flex;
  align-items: center;
}
.user-listing li .user-avatar.MuiAvatar-root {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: top;
  margin-right: 12px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-bottom: auto;
}
.user-listing li .user-details {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-grow: 1;
  cursor: pointer;
}
.user-listing li .user-details .user-name {
  margin: -8px 0 3px;
  transition: all 0.2s ease;
}
.user-listing li .MuiFormControlLabel-root {
  margin-left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user-listing li .MuiFormControlLabel-root .MuiCheckbox-root + .MuiFormControlLabel-label {
  padding-bottom: 0;
  display: flex;
  align-items: center;
}
.selected-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding: 15px 24px 20px;
  margin: 20px -24px -20px;
  width: calc(100% + 48px);
  background: var(--primary-25, #f2f7f8);
  box-shadow: 0px -4px 15px 0px rgba(9, 72, 73, 0.07);
}

.user-listing li .MuiBadge-root {
  margin-right: 12px;
}
.user-listing li .MuiBadge-root .user-avatar.MuiAvatar-root {
  margin-right: 0;
}
.user-listing li .MuiBadge-root .MuiBadge-badge {
  top: auto;
  bottom: -3px;
  right: 5px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  border: 2px solid var(--white);
}
.user-listing li .MuiBadge-root.accepted-invitation .MuiBadge-badge {
  background-color: #4caf50;
}
.user-listing li .MuiBadge-root.pending-invitation .MuiBadge-badge {
  background-color: #ffb200;
}

.availability-status-wrapper {
  padding: 4px;
}
.availability-status-wrapper .custom-badge + .custom-badge {
  margin-left: -4px;
}
.availability-status-wrapper .custom-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 1px solid var(--white);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 40px;
  font-size: 1rem;
  line-height: 1.4;
  padding-bottom: 3px;
}
.availability-status-wrapper .custom-badge.professional {
  background: var(--primary);
  color: var(--white);
}
.availability-status-wrapper .custom-badge.customer {
  background: #b3f0c2;
  color: var(--primary);
}

.inviting-product {
  padding: 8px 20px 12px;
  margin: -12px -24px 15px;
  width: calc(100% + 48px);
  background: #f2f7f8;
}

/* Responsive Media Query */
@media (max-width: 1439px) {
  .professional-profile-banner > .user .user-details {
    padding: 9px 30px 15px 128px;
  }
  .professional-profile-banner > .user .user-name {
    max-width: 360px;
  }
  .copied-text-wrapper {
    margin-top: 0;
    max-width: 260px;
  }

  .separate-listing li::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    margin: -2px 8px 0;
    display: inline-block;
    vertical-align: top;
    width: 3px;
    height: 3px;
    background: var(--color747474);
    border-radius: 50%;
  }
  .separate-listing li {
    display: block;
    padding-left: 18px;
  }
  .separate-listing li {
    margin-top: 4px;
  }
}
@media (max-width: 1199px) {
  .login-wrapper .login-inner {
    padding: 20px;
  }
  .header.MuiAppBar-root {
    padding: 10px 0 10px 8px;
  }
  .layout {
    padding-top: 64px;
  }
  .main-content {
    padding: 20px;
  }
  .MuiPopover-root.rating-count-dropdown .MuiBackdrop-root {
    display: block !important;
  }

  .professional-profile-banner > .user {
    padding-bottom: 45px;
  }
  .professional-profile-banner > .user .user-avatar {
    top: 50px;
  }
  .professional-profile-banner > .user .user-details {
    top: 130px;
    left: 0;
    right: 0;
    padding: 9px 20px 15px 128px;
    border-radius: 0;
  }
  .copied-text-wrapper {
    max-width: 330px;
  }
  .professional-profile-banner > .user .user-name {
    max-width: 100%;
  }
  .profile-details-actions {
    min-height: 100px;
    margin-bottom: 0;
  }
  .professional-profile-banner.client-details .user {
    padding-bottom: 0;
  }
  .professional-profile-banner.client-details .user .user-avatar {
    top: 0;
  }
  .professional-profile-banner.client-details .user .user-details {
    top: 70px;
  }
}
@media (max-width: 899px) {
  .login-wrapper .login-inner .login-body .login-form {
    padding: 30px;
    margin: 40px 0;
  }

  .professional-profile-banner > .user .user-name {
    font-size: 24px;
    max-width: 100%;
  }
  .professional-profile-banner > .user .user-avatar {
    top: 60px;
  }
  .professional-profile-banner > .user .user-designation {
    margin-bottom: 4px;
  }
  .professional-profile-banner > .user {
    padding-bottom: 56px;
  }
  .professional-profile-banner > .user .user-details {
    top: 130px;
    min-width: auto;
    padding: 9px 30px 15px 128px;
  }
  .professional-profile-banner .lifted-section {
    padding-left: 0;
    margin-top: 0;
  }
  .copied-text-wrapper {
    margin-right: 15px;
    margin-top: 0;
    margin-left: 8px;
    justify-content: flex-end;
    max-width: 100%;
  }
  .copied-text-wrapper .copied-text {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    max-width: 360px;
  }
  .copied-text-wrapper .btn-copy {
    z-index: 1;
  }
  .copied-text-wrapper .btn-copy {
    position: static;
  }

  .collapsible-table.MuiTableContainer-root
    .MuiTable-root
    .MuiTableBody-root
    .MuiTableRow-root
    .MuiTableCell-root
    .inner-table-wrapper
    .MuiCollapse-wrapperInner {
    overflow: auto;
  }
  .collapsible-table.MuiTableContainer-root
    .MuiTable-root
    .MuiTableBody-root
    .MuiTableRow-root
    .MuiTableCell-root
    .inner-table-wrapper
    .MuiCollapse-wrapper
    .inner-table {
    table-layout: auto;
    min-width: 700px;
  }
  .collapsible-table.MuiTableContainer-root
    .MuiTable-root
    .MuiTableBody-root
    .MuiTableRow-root
    .MuiTableCell-root
    .inner-table-wrapper {
    padding-left: 30px;
  }

  .MuiDialogContent-root .separator {
    width: calc(100% + 36px);
    margin: 20px -18px 16px -18px;
  }

  .audit-timeline-wrapper {
    padding: 10px 0 0;
  }
  .audit-timeline::before {
    left: 0;
    margin-left: 10px;
  }
  .audit-timeline .right-handle.completed::before,
  .audit-timeline .left-handle.completed::after,
  .audit-timeline .left-handle::after,
  .audit-timeline .right-handle::before {
    left: 0;
    right: auto;
    transform: translate(0, -50%);
  }
  .audit-timeline .right-handle.completed::before,
  .audit-timeline .left-handle.completed::after {
    margin-left: 0;
  }
  .audit-timeline .left-handle::after,
  .audit-timeline .right-handle::before {
    margin-left: 5px;
  }
  .audit-timeline-wrapper.project-history .audit-timeline .right-handle .box,
  .audit-timeline-wrapper.project-history .audit-timeline .left-handle .box,
  .audit-timeline .right-handle .box,
  .audit-timeline .left-handle .box {
    max-width: none;
    text-align: left;
    min-width: 100%;
  }
  .audit-timeline .right-handle,
  .audit-timeline .left-handle {
    text-align: left;
    padding-left: 35px;
    margin-bottom: 15px;
  }
  .audit-timeline .left-handle .box.steps,
  .audit-timeline .right-handle .box.steps {
    width: auto;
  }

  .project-accordion.MuiAccordion-root {
    border-radius: 14px !important;
  }
  .project-accordion.MuiAccordion-root .MuiCollapse-root .MuiAccordionDetails-root {
    padding: 20px;
  }
  .project-accordion.MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-content {
    flex-direction: column;
    align-items: flex-start;
    margin: 12px 12px 16px 8px;
  }
  .project-accordion.MuiAccordion-root
    .MuiAccordionSummary-root
    .MuiAccordionSummary-content
    .right-action {
    margin-top: 12px;
    justify-content: space-between;
  }
  .project-accordion.MuiAccordion-root .MuiAccordionSummary-root {
    padding: 0 10px;
  }

  .selected-footer {
    padding: 15px 18px 20px;
    margin: 20px -18px -20px;
    width: calc(100% + 36px);
  }

  .inviting-product {
    margin: -12px -20px 15px;
    width: calc(100% + 40px);
  }
}
@media (max-width: 599px) {
  .login-wrapper .login-inner {
    padding: 30px 0;
  }
  .login-wrapper .login-inner .login-body .login-form {
    padding: 24px;
    margin: 30px 0;
  }
  .header.MuiAppBar-root {
    padding: 8px 0 8px 8px;
  }
  .header.MuiAppBar-root .mb-6p {
    margin-bottom: 4px !important;
  }
  .header.MuiAppBar-root.action-header .MuiButtonBase-root,
  .header.MuiAppBar-root.action-header .MuiButtonBase-root {
    box-shadow: none !important;
    min-height: 40px;
  }
  .header.MuiAppBar-root.action-header .MuiButtonBase-root.has-icon .MuiButton-startIcon,
  .header.MuiAppBar-root.action-header .MuiButtonBase-root.has-icon .MuiButton-endIcon {
    display: block;
  }
  .header.MuiAppBar-root.action-header .MuiButtonBase-root.MuiButton-contained {
    padding: 5px 16px 11px;
  }
  .layout {
    padding-top: 60px;
  }
  .main-content {
    padding: 20px 16px;
  }
  .collapsible-table.MuiTableContainer-root
    .MuiTable-root
    .MuiTableBody-root
    .MuiTableRow-root
    .MuiTableCell-root
    .inner-table-wrapper {
    padding-left: 25px;
  }
  .MuiTableContainer-root.action-focus-table .MuiTable-root,
  .MuiTableContainer-root.action-focus-table .MuiTable-root .MuiTableBody-root,
  .MuiTableContainer-root.action-focus-table .MuiTable-root .MuiTableRow-root,
  .action-focus-table.MuiTableContainer-root .MuiTable-root .MuiTableRow-root .MuiTableCell-root {
    display: block;
  }
  .action-focus-table.MuiTableContainer-root .MuiTable-root .MuiTableRow-root .MuiTableCell-root {
    border: 0;
  }
  .action-focus-table.MuiTableContainer-root .MuiTable-root .MuiTableRow-root:first-child {
    border-top: 1px solid #e1e1e1;
  }
  .action-focus-table.MuiTableContainer-root .MuiTable-root .MuiTableRow-root {
    border-bottom: 1px solid #e1e1e1;
    padding: 6px 0;
  }
  .action-focus-table.MuiTableContainer-root
    .MuiTable-root
    .MuiTableRow-root
    .MuiTableCell-root:last-child
    .border-left {
    border: 0;
    padding-left: 0;
    height: auto;
    margin-top: -6px;
  }
  .action-focus-table.MuiTableContainer-root
    .MuiTable-root
    .MuiTableRow-root
    .MuiTableCell-root:first-child {
    padding: 6px 10px;
  }
  .action-focus-table.MuiTableContainer-root
    .MuiTable-root
    .MuiTableRow-root
    .MuiTableCell-root:last-child
    .border-left
    .btn-link {
    padding: 4px 20px 8px;
    background: #e1f1f4;
    border-radius: 30px;
  }
  .collapsible-table.MuiTableContainer-root
    .MuiTable-root
    .MuiTableBody-root
    .MuiTableRow-root
    .MuiTableCell-root
    .inner-table-wrapper {
    padding: 0;
  }
  .collapsible-table.MuiTableContainer-root
    .MuiTable-root
    .MuiTableBody-root
    .MuiTableRow-root
    .MuiTableCell-root
    .inner-table-wrapper
    .MuiCollapse-wrapper
    .inner-table
    .MuiTableHead-root
    .MuiTableCell-head:first-child {
    padding: 9px 18px 11px;
  }
  .collapsible-table.MuiTableContainer-root
    .MuiTable-root
    .MuiTableBody-root
    .MuiTableRow-root
    .MuiTableCell-root
    .inner-table-wrapper
    .MuiCollapse-wrapper
    .inner-table
    .MuiTableBody-root
    .MuiTableCell-body:first-child {
    padding: 9px 18px 11px;
  }

  .no-data-found {
    padding: 100px 20px;
  }

  .MuiPopover-root.notes-popover.simple-tooltip .MuiPaper-root {
    max-width: 100%;
    min-width: 100%;
    max-height: 100%;
    position: fixed;
    top: 0 !important;
    bottom: 0;
    left: 0 !important;
    right: 0;
    margin: 0;
    transform: none !important;
    border-radius: 0;
  }
  .notes-wrapper {
    height: calc(100% - 124px);
  }
  .notes-wrapper .notes-list {
    max-height: 100%;
    min-height: 100%;
  }
  .notes-wrapper .close-header {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
  }
  .notes-wrapper .notes-action-bar {
    padding: 12px;
  }

  .professional-profile-banner > .user {
    display: block;
    padding-bottom: 0;
    margin-bottom: 16px;
  }
  .professional-profile-banner > .user .user-avatar {
    top: 0;
    border-radius: 18px;
    margin-right: 10px;
  }
  .professional-profile-banner > .user .user-avatar + .MuiButtonBase-root {
    padding: 6.5px 14px 11.5px;
  }
  .professional-profile-banner > .user .user-avatar .user-avatar-img {
    border-radius: 14px;
  }
  .professional-profile-banner > .user .user-details {
    position: static;
    display: inline-block;
    width: calc(100% + 40px);
    padding: 50px 24px 15px 24px;
    margin: -40px -20px 0;
    border-radius: 0;
  }
  .professional-profile-banner > .user .user-name {
    font-size: 24px;
    margin-bottom: 0;
    max-width: 100%;
  }
  .copied-text-wrapper {
    margin-right: 0;
    margin-top: 0;
    margin-left: 8px;
    justify-content: flex-end;
    max-width: 100%;
  }
  .copied-text-wrapper .copied-text {
    max-width: 100%;
  }
  .copied-text-wrapper .copied-text {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    padding: 8px 45px 8px 15px;
  }
  .copied-text-wrapper .btn-copy {
    z-index: 1;
    position: static;
  }

  .project-accordion.MuiAccordion-root .MuiCollapse-root .MuiAccordionDetails-root .user {
    display: block;
  }
  .project-accordion.MuiAccordion-root
    .MuiCollapse-root
    .MuiAccordionDetails-root
    .user
    .user-image {
    margin-bottom: 10px;
    width: 63px;
    height: 80px;
  }
  .project-accordion.MuiAccordion-root .MuiCollapse-root .MuiAccordionDetails-root .inner-card {
    display: block;
    padding-bottom: 5px;
  }
  .project-accordion.MuiAccordion-root
    .MuiCollapse-root
    .MuiAccordionDetails-root
    .inner-card
    > li {
    width: 50%;
    max-width: 50%;
    margin-bottom: 20px;
    border-left: 0;
    text-align: center;
  }
  .project-accordion.MuiAccordion-root
    .MuiCollapse-root
    .MuiAccordionDetails-root
    .inner-card
    > li:nth-child(odd) {
    border-left: 0;
  }

  .fixed-footer {
    padding: 18px !important;
  }

  .price-table tr {
    display: block;
  }
  .price-table tr td {
    padding: 8px;
    display: block;
    width: auto;
  }
  .price-table tr td .MuiTypography-root {
    max-width: 300px;
  }
  .price-table tr td:last-child {
    padding: 0 8px 8px;
  }

  .check-action-grid tr td:first-child {
    display: block;
    width: 100%;
  }
  .check-action-grid tr td {
    display: inline-block;
    padding: 4px 20px 4px 0;
  }
  .check-action-grid tr {
    display: block;
    padding-bottom: 8px;
  }
  .check-action-grid tr .static-error {
    margin-top: -10px !important;
  }

  .user-card,
  .light-bordered-box {
    padding: 22px 18px 24px;
  }
}
@media (max-width: 475px) {
  .project-accordion.MuiAccordion-root .MuiCollapse-root .MuiAccordionDetails-root .inner-card {
    text-align: center;
    padding: 15px 10px;
  }
  .project-accordion.MuiAccordion-root
    .MuiCollapse-root
    .MuiAccordionDetails-root
    .inner-card
    > li {
    width: 51%;
    max-width: 51%;
    padding: 20px 0 10px;
    margin: 10px auto 0;
  }
  .project-accordion.MuiAccordion-root
    .MuiCollapse-root
    .MuiAccordionDetails-root
    .inner-card
    > li:first-child {
    margin-top: 0;
    padding-top: 0;
  }
  .project-accordion.MuiAccordion-root
    .MuiCollapse-root
    .MuiAccordionDetails-root
    .inner-card
    > li
    + li {
    border-top: 1px solid #e1e1e1;
    border-left: 0;
  }

  .project-accordion.MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-content {
    display: block;
    margin: 12px 4px;
  }
  .project-accordion.MuiAccordion-root
    .MuiAccordionSummary-root
    .MuiAccordionSummary-content
    .MuiChip-root {
    margin-left: 0 !important;
    margin-top: 6px;
  }
}

@media (min-width: 899px) {
  .MuiTooltip-tooltip.copy-url-tooltip {
    min-width: 500px;
  }
}
@media (min-width: 1199px) {
  .header.MuiAppBar-root.open {
    padding-left: 292px;
  }
  .layout.open {
    padding-left: 280px;
  }
}
@media (min-width: 1366px) {
  .collapsible-table.MuiTableContainer-root .MuiTable-root {
    table-layout: fixed;
  }
}
@media (min-width: 1440px) {
  .MuiContainer-root {
    max-width: 1344px !important;
  }
}
